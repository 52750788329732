.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  background-color: #0000006e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalStyle1 {
  position: absolute;
  top: 10%;
  left: 10%;
  overflow: scroll;
  height: 100%;
  display: block;
}

.popup {
  background-color: #fff;
  position: relative;
  display: flex;
  width: 750px;
}
.form_register_popup {
  width: 55%;
  padding: 30px;
}
.title {
  font-family: var(--neue-reg-font);
  font-size: 1.7rem;
}
.desc {
  font-size: 1.1rem;
  margin: 15px 0;
}
.bg_img {
  position: relative;
  width: 45%;
  height: auto;
  background-color: rgb(211, 211, 211);
}
.bg_img img {
  object-fit: cover;
}
.input_group {
  display: flex;
  flex-direction: column;
}
.name_input,
.email_input,
.birthday_input {
  width: 90%;
  margin: 5px 0;
}
.button_register div {
  width: 100%;
  text-align: center;
}
.close_icon_popup {
  position: absolute;
  color: #000000;
  right: 15px;
  top: 15px;
  font-size: 1.4rem;
  cursor: pointer;
}

/** MOBILE BREAKPOINT */

@media only screen and (max-width: 1023px) {
  .popup {
    width: 650px;
  }
}
@media only screen and (max-width: 650px) {
  .popup {
    width: 350px;
    flex-direction: column-reverse;
    scale: 0.9;
  }
  .form_register_popup {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .bg_img {
    width: 100%;
    height: 180px;
  }

  .title {
    font-size: 0.9rem;
  }
  .desc {
    font-size: 0.9rem;
  }
  .close_icon_popup {
    font-size: 1.2rem;
    right: 15px;
  }
  .name_input input,
  .email_input input,
  .name_input label,
  .email_input label {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 375px) {
  .popup {
    width: 300px;
    scale: 0.8;
  }
  .form_register_popup {
    padding: 10px;
  }
  .bg_img {
    height: 170px;
  }

  .title {
    font-size: 1rem;
  }
  .desc {
    font-size: 0.9rem;
  }

  .close_icon_popup {
    right: 15px;
    font-size: 1.2rem;
  }
}
