.title {
  font-family: var(--neuzeit-reg-font);
  text-transform: uppercase;
}
.signup_form {
  display: flex;
  flex-direction: column;
}
.button_register div {
  width: 100%;
  text-align: center;
  margin: 20px 0 10px;
}
.first_name_input,
.last_name_input,
.email_input,
.phone_input,
.password_input,
.password_confirmation_input {
  margin: 7px 0 !important;
}
